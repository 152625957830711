<template>
    <v-card flat>
      
        <v-card-text>
          <v-card flat class="mx-auto" :class="{padding:!loaded}">
            <v-card-text>
            <v-row justify="center" class=""  v-if="!loaded">
              <v-col lg="6" sm="10">
                  <v-col cols="12">
                    <div class="entry-text">
                      <h1>Whois lookup</h1>
                      <p>Search Domain or Ip adddress at ubercompute whois service and get verified registration information from whois database.</p>

                    </div>
                  </v-col>
                </v-col>
          </v-row>
          <v-row  justify="center">
              <v-col cols="10" lg="6">
               <v-row class="text-center" no-gutters>
                 <v-col cols="12" lg="8">
                   <ValidationObserver ref="observer">
                     <ValidationProvider v-slot="{ errors }" name="domain" :rules="{required:true,regex:/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$|\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,max:30}">
                    <v-text-field
                    :error-messages="errors"
                    v-model="domain"
                    clearable
                    label="Domain"
                    type="text"
                    outlined
                    color="purple"
                  >
                  </v-text-field>
                     </ValidationProvider>
                   </ValidationObserver>
                
                 </v-col>
                 <v-col cols="12" lg="4" class="pl-lg-4">
                   <vue-recaptcha ref="recaptcha"
                    @verify="onVerify" sitekey="6LedyKUZAAAAAIUm0jhhH0NUQv2FHomXdLaLNcvO"></vue-recaptcha>
                   <v-btn x-large @click="getWhois" color="purple" :loading="isLoading" block dark><v-icon left>mdi-shield-search</v-icon>  <span class="mr-2">Search</span></v-btn>
                   
                 </v-col>
               </v-row>
              </v-col>
               
          </v-row>
            </v-card-text>
          </v-card>
          <v-card flat>
             
          <v-row>
            <v-col cols="12" md="10" class="mx-auto">
              <v-row>
                <v-col cols="12" md="8">
                  <Records :records="records" v-if="loaded && !isLoading"/>
                  <v-skeleton-loader type="card-heading,divider, list-item-three-line,article,article" v-if="isLoading"></v-skeleton-loader>
                  <v-alert
                    prominent
                    outlined
                    type="error"
                    v-if="error"
                  >
                    <v-row align="center">
                      <v-col class="grow">{{msg}}</v-col>
                      <v-col class="shrink">
                        <v-btn @click="registerDomain">Register Now</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                  
                  <v-card v-if="!loaded && !isLoading">
                    <v-card-text>
                      <h4>What is Whois ?</h4>
                      <p>WHOIS (pronounced as the phrase "who is") is a query and response protocol that is widely used for querying databases that store the registered users or assignees of an Internet resource, such as a domain name, an IP address block or an autonomous system, but is also used for a wider range of other information. The protocol stores and delivers database content in a human-readable format.[1] The current iteration of the WHOIS protocol was drafted by the Internet Society, and is documented in RFC 3912.</p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3" offset-md="1">
                  <Sidebar/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import { required, max,min, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import VueRecaptcha from 'vue-recaptcha';
import Records from './Records';
import Sidebar from './Sidebar';

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
extend('regex', {
  ...regex,
  message: '{_field_} should be a valid domain or ip address',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})
extend('min', {
  ...min,
  message: '{_field_} may not be less than {length} characters',
})

import axios from 'axios'
export default {
    name:'entry',
    components: {
      ValidationProvider,
      ValidationObserver,
      Records,
      Sidebar,
      VueRecaptcha
    },
    data:()=>({
      domain:'',
      isLoading:false,
      loaded:false,
      error:false,
      msg:'',
      robot:true,
      records:{}
    }),
    methods:{
      async getWhois(){
        const isValid = await this.$refs.observer.validate()
        if(isValid){
          this.isLoading = true;
          try {
            // const response = await axios.post('http://localhost:8000/v1/domain/whois',{data:this.domain});
            const response = await axios.post('https://api.ubercompute.com/v1/domain/whois',{data:this.domain});
            // console.log(response.data.raw.substr(0,18).toLowerCase()); 
            if(response.data.raw.substr(0,19).toLowerCase() == 'no match for domain' || response.data.raw.substr(0,18).toLowerCase() == '% this is the ripe'){
              this.loaded = false;
              this.isLoading = false;
              this.error = true;
              this.msg = 'Looks like this domain'+this.domain+' has not been registered yet'
              return;
            }
            this.formateRecords(response.data.raw);
          } catch (error) {
            // console.error(error);
            this.loaded = false;
            this.isLoading = false;
            this.error = true;
            this.msg = 'Looks like this domain'+this.domain+' has not been registered yet'
          }
        }
      },
      formateRecords(raw){
          let rawArray = raw.split('\n');
          let recObj = new Object();
          let ns = [];
          // let regx1 = / /;
          // let regx2 = /\//;
          // let regex = new RegExp(regx1.source+regx2.source,'g');
          rawArray.forEach((entry)=>{
              let entryArray = entry.split(': ');
              // let entryObj = new Object();
              if(entryArray[0].toLowerCase().replace(/ /g,"_") == 'name_server'){
                ns.push((typeof entryArray[1] != 'undefined') ?  entryArray[1] :'');
              }
              recObj[entryArray[0].toLowerCase().replace(/ /g,"_").replace('/','_')] = (typeof entryArray[1] != 'undefined') ?  entryArray[1] :'';
              // this.records.push(entryObj)
          })
          recObj.ns = ns;
          recObj.raw = raw;
         this.records = recObj;
         this.loaded = true;
         this.isLoading = false;
      },
      registerDomain(){
        window.location.href="https://www.dpbolvw.net/click-100159540-13266668";
      },
      onVerify: function (response) {
      if (response) this.robot = false;
    },   
    },
    
}
</script>
<style scoped>
.entry-text p {
    font-size: 16px;
}
.entry-text h1 {
    font-size: 32px;
    color: #9c27b0;
    margin-bottom: 1rem;
}
.entry-text {
    text-align: center;
    padding: 10px;
    margin-bottom: 2rem;
}
.padding {
    padding: 6rem 0;
}
.v-application p {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 26px;
}
h4 {
    font-size: 20px;
    line-height: 32px;
}

</style>