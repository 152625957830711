<template>
    <v-app-bar
      app
      color="purple"
      dark
    >
      <div class="d-flex align-center">

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1"
          contain
          min-width="80"
          src="../assets/logo.png"
          width="150"
          transition="scale-transition"
        />
      </div>

      <v-spacer></v-spacer>
      <v-btn
        href="https://www.ubercompute.com/formatter"
        target="_blank"
        text
      >
        <v-icon>mdi-menu</v-icon>
         <span class="ml-2 d-md-block d-none">Formatters</span>
      </v-btn>
      <v-btn
        href="https://www.ubercompute.com/converter"
        target="_blank"
        text
      >
        <v-icon>mdi-swap-horizontal</v-icon>
         <span class="ml-2 d-md-block d-none">Converters</span>
      </v-btn>
      <v-btn
        href="https://www.ubercompute.com/minifiers"
        target="_blank"
        text
      >
        <v-icon>mdi-arrow-collapse-all</v-icon>
         <span class="ml-2 d-md-block d-none">Minifiers</span>
      </v-btn>
      <v-btn
        href="https://dns.ubercompute.com/"
        target="_blank"
        text
      >
        <v-icon>mdi-web</v-icon>
         <span class="ml-2 d-md-block d-none">DNS Lookup</span>
      </v-btn>
    </v-app-bar>
</template>
<script>
export default{
    name:'app-bar'
    
}
</script>