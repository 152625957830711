<template>
  <div>
      <v-card class="widget">
          <a href="https://www.tkqlhce.com/click-100159540-13987078" target="_top">
            <v-img class="white--text align-end ads-img" src="https://www.awltovhc.com/image-100159540-13987078" width="100%" alt="Save up to 86% with our Domain & Shared Hosting package deal!" border="0"></v-img>
            </a>
      </v-card>
      
      
      <v-card class="widget">
         <v-card
        color="purple"
        flat
        >
        <v-list dense dark short color="purple" class="no-padding">
            <v-list-item>
                <v-list-item-avatar color="white" size="30">
                    <v-icon color="purple">mdi-swap-horizontal</v-icon>
                </v-list-item-avatar>
                 <v-list-item-content>
                    <v-list-item-title>Code Converters</v-list-item-title>
                 </v-list-item-content>
            </v-list-item>
        </v-list>

      
        </v-card>
    <v-list dense>
        <v-list-item v-for="(converter,index) in converts" :key="index" :href="'https://www.ubercompute.com/'+converter.link" target="_blank">
          <v-list-item-content>
            <v-list-item-title>{{converter.title}}</v-list-item-title>
            <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
           
          </v-list-item-content>
          
        </v-list-item>
    </v-list>
  </v-card>

  <v-card class="widget">
         <v-card
        color="purple"
        flat
        >
        <v-list dense dark short color="purple" class="no-padding">
            <v-list-item>
                <v-list-item-avatar color="white" size="30">
                    <v-icon color="purple">mdi-menu</v-icon>
                </v-list-item-avatar>
                 <v-list-item-content>
                    <v-list-item-title>Code Formatter</v-list-item-title>
                 </v-list-item-content>
            </v-list-item>
        </v-list>

      
        </v-card>
    <v-list dense>
        <v-list-item v-for="(formatter,index) in formatters" :key="index" :href="'https://www.ubercompute.com/'+(formatter.title).toLowerCase()+'-formatter'" target="_blank">
          <v-list-item-content>
            <v-list-item-title>{{formatter.title}} Formatter</v-list-item-title>
            <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
           
          </v-list-item-content>
          
        </v-list-item>
    </v-list>
  </v-card>
  </div>
</template>
<script>
export default {
    name:'sidebar',
    data:()=>({
        converts:[
           {
            title:'Pug to Html',
            link:'pug-to-html',
            },
            {
            title:'Html to Jade',
            link:'html-to-jade',
            },
            {
            title:'SCSS to CSS',
            link:'scss-to-css',
            },
            {
            title:'SASS to CSS',
            link:'sass-to-css',
            },
            {
            title:'YAML to Javascript',
            link:'yaml-to-js',
            },
            {
            title:'Jade to Html',
            link:'jade-to-html',
            },
            {
            title:'Javascript to Cofeescript',
            link:'javascript-to-coffescript',
            },
            {
            title:'XML to JSON',
            link:'xml-to-json',
            },
            {
            title:'JSON to XML',
            link:'json-to-xml',
            }
        ],
    formatters:[
           {
            title:'Html'
           },{
            title:'CSS'
           },{
            title:'Javascript',
           },{
            title:'SCSS'
           },{
            title:'LESS'
           },{
            title:'PHP'
           },{
            title:'JSON'
           },{
            title:'XML'
           },{
            title:'MARKDOWN'
           },{
            title:'GraphQl'
           },{
            title:'YAML'
           },{
            title:'Typescript'
           }
    ]
    })
}
</script>
<style scoped>
.no-padding{
    padding: 0;
}
.widget {
    margin-bottom: 2rem;
}
.ads-img{
    object-fit: contain;
}
</style>