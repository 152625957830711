<template>
    <v-footer
    padless
    color="grey lighten-3"
  >
            <v-card
                class="flex"
                flat
                tile
                color="grey lighten-3"
                >
                <v-row no-gutters>
                    <v-col cols="12" md="10" class="mx-auto">   
                        <v-card-title>
                            <strong class="subheading">Get connected with us on social networks!</strong>

                            <v-spacer></v-spacer>

                            <v-btn
                            class="mx-4"
                            icon
                            color="purple"
                            href="https://www.facebook.com/ubercompute"
                            target="_blank"
                            >
                            <v-icon size="24px">mdi-facebook</v-icon>
                            </v-btn>
                            <v-btn
                            class="mx-4"
                            icon
                            color="purple"
                            href="https://twitter.com/ubercompute"
                            target="_blank"
                            >
                            <v-icon size="24px">mdi-twitter</v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-col>
                </v-row>

                <v-card flat color="purple darken-1">
                    <v-row no-gutters>
                    <v-col cols="12" md="10" class="mx-auto"> 
                        <v-card-text class="py-2 white--text text-center">
                            Copyright {{ new Date().getFullYear() }} — <strong>Ubercompute Whois Service</strong> | All Rights Reserved | <a href="https://www.ubercompute.com/disclaimer">Disclaimer</a>
                        </v-card-text>
                        </v-col>
                     </v-row>
                </v-card>
                </v-card>
        
  </v-footer>
</template>
<script>
export default {
    name:'app-footer'
}
</script>
<style scoped>
.subheading{
    color: #333;
}
a{
    color: #fff !important;
    text-decoration: none;
}
</style>