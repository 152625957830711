<template>
    <v-card flat>
            <v-row>
              <v-col cols="12">
                <v-card v-if="typeof records.domain_name != 'undefined'">
                  <v-card color="purple" dark class="card-hedear">
                    <v-card-title class="record-title">
                      Domain Info
                    </v-card-title>
                  </v-card>
                  <v-card-text class="record-info">
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Name:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{(typeof records.domain_name != 'undefined') ? (records.domain_name).toLowerCase(): ""}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Domain Id:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registry_domain_id}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Registered On:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.creation_date}} | <v-chip small>{{moment(moment(records.creation_date).format("YYYYMMDD"), "YYYYMMDD").fromNow()}}</v-chip></p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Updated On:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.updated_date}} | <v-chip small>{{moment(moment(records.updated_date).format("YYYYMMDD"), "YYYYMMDD").fromNow()}}</v-chip></p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Domain Status:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.domain_status}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Expired In:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrar_registration_expiration_date}} | <v-chip small>{{moment(moment(records.registrar_registration_expiration_date).format("YYYYMMDD"), "YYYYMMDD").fromNow()}}</v-chip></p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Registrar:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrar}}</p>
                        <p>{{records.registrar_url}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                        Name Servers:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <v-chip outlined color="purple" class="mr-2" :key="i" v-for="(ns,i) in records.ns">{{ns}}</v-chip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- registrant info -->
                <v-card v-if="typeof records.domain_name != 'undefined'">
                  <v-card color="purple" dark class="card-hedear">
                    <v-card-title class="record-title">
                      Registrant Info
                    </v-card-title>
                  </v-card>
                  <v-card-text class="record-info">
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Name:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_name}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Email:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_email}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Phone:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_phone}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Organisation:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_organization}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         City:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_city}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         State:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_state_province}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Name:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_name}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Country:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_country}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Pincode:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.registrant_postal_code}}</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>


                <!-- Administrative  info -->
                <v-card v-if="typeof records.domain_name != 'undefined'">
                  <v-card color="purple" dark class="card-hedear">
                    <v-card-title class="record-title">
                      Administrative Info
                    </v-card-title>
                  </v-card>
                  <v-card-text class="record-info">
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Name:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_name}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Email:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_email}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Phone:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_phone}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Organisation:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_organization}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         City:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_city}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         State:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_state_province}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Name:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_name}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Country:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_country}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Pincode:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.admin_postal_code}}</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- Technical  info -->
                <v-card v-if="typeof records.domain_name != 'undefined'">
                  <v-card color="purple" dark class="card-hedear">
                    <v-card-title class="record-title">
                      Technical Info
                    </v-card-title>
                  </v-card>
                  <v-card-text class="record-info">
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Name:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_name}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Email:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_email}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Phone:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_phone}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Organisation:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_organization}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         City:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_city}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         State:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_state_province}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Name:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_name}}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Country:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_country}}</p>
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col cols="12" md="4" class="rPad">
                       <h4>
                         Pincode:
                       </h4>
                      </v-col>
                      <v-col cols="12" md="8" class="rPad">
                        <p>{{records.tech_postal_code}}</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>



                <v-card>
                  <v-card color="purple" dark class="card-hedear">
                    <v-card-title class="record-title">
                      Raw Data
                    </v-card-title>
                  </v-card>
                  <v-card-text class="record-info text-pre">
                    <p>{{records.raw}}</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" class="rPad">
              </v-col>
            </v-row>
          </v-card>
</template>
<script>

export default {
    name:'records',
    props:{records:Object},
    watch:{
        
    }

}
</script>
<style scoped>
.record-info {
    margin-bottom: 2.5rem;
}
.record-info.text-pre {
    white-space: pre-line;
}
.record-info p {
    font-size: 16px;
    margin: 0;
    color: #333;
    font-size: 14px;
}
.record-info h4 {
    font-weight: bold;
    font-size: 14px;
    color: #9c27b0;
}
.card-hedear {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.record-title {
    font-size: 18px;
    padding: 10px 15px;
}
.rPad {
    padding: 7px 10px;
}
</style>