<template>
  <v-app>
    <Header></Header>

    <v-main>
      <Entry/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>

import Header from './components/Header';
import Entry from './components/Entry';
import Footer from './components/Footer';

export default {
  name: 'App',

  components: {
    Header,
    Entry,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
